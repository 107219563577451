<template>
  <div>
    <filter-slot
      :filter="visibleFilters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs.refSuccessList.refresh()"
      @reset-all-filters="updateTable"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refSuccessList"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(advisor_name)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(eeuu_state)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(connection_updated_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #cell(lead_name)="data">
            <router-link
              :class="[textLink]"
              :to="{
                name: 'connection-clients-account',
                params: {
                  idClient: data.item.account_id,
                },
              }"
              target="_blank"
            >{{ data.item.lead_name }}</router-link>
            <br>
            <span>{{ data.item.account }}</span>
            <status-account
              :account="data.item"
              :text="true"
            />
            <span style="font-weight: 100">{{ data.item.lead_mobile }}</span>
          </template>
          <template #cell(advisor_name)="data">
            <div style="position: relative">
              <span style="margin-left: 15px">
                {{ data.item.advisor_name }}
                <div
                  :title="
                    data.item.advisor_state == 1
                      ? 'Active'
                      : data.item.advisor_state == 2
                        ? 'Busy'
                        : data.item.advisor_state == 3
                          ? 'Away'
                          : 'Offline'
                  "
                  :style="
                    data.item.advisor_state == 1
                      ? 'background:green'
                      : data.item.advisor_state == 2
                        ? 'background:orange'
                        : data.item.advisor_state == 3
                          ? 'background:red'
                          : 'background:#ccc'
                  "
                  style="
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    position: absolute;
                    left: 20%;
                    top: 50%;
                    transform: translate(0, -50%);
                  "
                />
              </span>
            </div>
          </template>
          <template #cell(advisor_connection_name)="data">
            <div>
              <div style="position: relative">
                <span style="margin-left: 20px">
                  {{ data.item.advisor_connection_name }}
                  <div
                    :title="
                      data.item.advisor_connection_state == 1
                        ? 'Active'
                        : data.item.advisor_connection_state == 2
                          ? 'Busy'
                          : data.item.advisor_connection_state == 3
                            ? 'Away'
                            : 'Offline'
                    "
                    :style="
                      data.item.advisor_connection_state == 1
                        ? 'background:green'
                        : data.item.advisor_connection_state == 2
                          ? 'background:orange'
                          : data.item.advisor_connection_state == 3
                            ? 'background:red'
                            : 'background:#ccc'
                    "
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 50%;
                      position: absolute;
                      left: 0;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  />
                </span>
              </div>
            </div>
          </template>
          <template #cell(connection_updated_at)="data">
            <div class="text-center">
              <span>{{ data.item.connection_updated_at | myGlobal }}</span>
            </div>
          </template>
          <template #cell(eeuu_state)="data">
            <div class="text-center">
              <span>{{ data.item.eeuu_state }}</span>
            </div>
          </template>
          <template #cell(tracking)="data">
            <feather-icon
              icon="MenuIcon"
              class="cursor-pointer text-primary"
              size="15"
              @click="openTrackingModal(data.item.account_id)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <tracking-modal
      v-if="isTrackingModalOpened"
      :current-client-id="currentClientId"
      @close="closeTrackingModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Fields from '@/views/commons/components/success/data/fields.success.data';
import Filters from '@/views/commons/components/success/data/filters.success.data';
import ClientsConnectionService from '@/views/commons/components/success/services/connection.service';
import TrackingModal from '@/views/ce-digital/sub-modules/connection/views/clients/components/modals/TrackingModal.vue';
import ClientsConnectionServiceCedigital from '@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service';
import StatusAccount from '@/views/commons/components/clients/stylescomponents/StatusAccount.vue';

export default {
  components: {
    TrackingModal,
    StatusAccount,
  },
  data() {
    return {
      serviceActionType: null,
      actionsOptions: [],
      advisorsList: [],
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      clients: [],
      currentClientId: '',
      endPage: '',
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Client...',
        model: '',
      },
      isBusy: false,
      isTrackingModalOpened: false,
      nextPage: '',
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      toPage: null,
      totalRows: 0,
      sortBy: '',
      sortDesc: true,
      startPage: null,
    };
  },
  computed: {
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentRoleId() {
      return this.currentUser.role_id;
    },
    currentUserId() {
      return this.currentUser.user_id;
    },
    visibleFilters() {
      if (!this.isAgent) {
        this.filters[0].visible = true;
        return this.filters.filter(filter => filter.visible);
      }
      return this.filters.filter(filter => filter.visible);
    },
    isAgent() {
      return this.currentRoleId === 15;
    },
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
    statusTab() {
      return this.$route.meta.status;
    },
  },
  async created() {
    await this.getAllAdvisors();
  },
  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          search_text: this.filterPrincipal.model,
          in_connection: 'CONNECTION',
          client_connection_state: 'RECOVERED',
          date_from: this.filters[2].model ? this.filters[2].model : null,
          date_to: this.filters[3].model ? this.filters[3].model : null,
          advisor_code: this.filters[0].model,
          client_status: this.statusTab,
          per_page: this.paginate.perPage,
          user_role: this.currentRoleId,
          user_id: this.currentUserId,
          page: this.paginate.currentPage,
        };
        const { data } = await ClientsConnectionService.getConnectionClients(
          params,
        );
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return data.data;
      } catch (err) {
        console.error(err);
      }
    },
    updateTable() {
      this.$refs.refSuccessList.refresh();
    },
    async getAllAdvisors() {
      try {
        const { data } = await ClientsConnectionServiceCedigital.getUserModule();
        this.filters[0].options = data || [];
      } catch (error) {
        console.log(error);
      }
    },
    openTrackingModal(clientAccountId) {
      this.isTrackingModalOpened = true;
      this.currentClientId = clientAccountId;
    },
    closeTrackingModal() {
      this.isTrackingModalOpened = false;
    },
  },
};
</script>

<style scoped>
.custom_button {
  background-color: #1b7d14;
  width: 90px;
  max-width: 120px;
  height: 30px;
  box-shadow: 0 2px 6px rgb(191, 191, 191);
  border: none;
  border-radius: 7px !important;
}

.status_circle {
  height: 10px;
  background: red;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 15px !important;
}

.bg-unread {
  background-color: rgb(255, 255, 223) !important;
}
</style>
