var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.visibleFilters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs.refSuccessList.refresh()},"reset-all-filters":_vm.updateTable},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refSuccessList",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.visibleFields,"primary-key":"id","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"head(advisor_name)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"head(eeuu_state)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"head(connection_updated_at)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"cell(lead_name)",fn:function(data){return [_c('router-link',{class:[_vm.textLink],attrs:{"to":{
              name: 'connection-clients-account',
              params: {
                idClient: data.item.account_id,
              },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]),_c('br'),_c('span',[_vm._v(_vm._s(data.item.account))]),_c('status-account',{attrs:{"account":data.item,"text":true}}),_c('span',{staticStyle:{"font-weight":"100"}},[_vm._v(_vm._s(data.item.lead_mobile))])]}},{key:"cell(advisor_name)",fn:function(data){return [_c('div',{staticStyle:{"position":"relative"}},[_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v(" "+_vm._s(data.item.advisor_name)+" "),_c('div',{staticStyle:{"height":"10px","width":"10px","border-radius":"50%","position":"absolute","left":"20%","top":"50%","transform":"translate(0, -50%)"},style:(data.item.advisor_state == 1
                    ? 'background:green'
                    : data.item.advisor_state == 2
                      ? 'background:orange'
                      : data.item.advisor_state == 3
                        ? 'background:red'
                        : 'background:#ccc'),attrs:{"title":data.item.advisor_state == 1
                    ? 'Active'
                    : data.item.advisor_state == 2
                      ? 'Busy'
                      : data.item.advisor_state == 3
                        ? 'Away'
                        : 'Offline'}})])])]}},{key:"cell(advisor_connection_name)",fn:function(data){return [_c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(" "+_vm._s(data.item.advisor_connection_name)+" "),_c('div',{staticStyle:{"height":"10px","width":"10px","border-radius":"50%","position":"absolute","left":"0","top":"50%","transform":"translate(0, -50%)"},style:(data.item.advisor_connection_state == 1
                      ? 'background:green'
                      : data.item.advisor_connection_state == 2
                        ? 'background:orange'
                        : data.item.advisor_connection_state == 3
                          ? 'background:red'
                          : 'background:#ccc'),attrs:{"title":data.item.advisor_connection_state == 1
                      ? 'Active'
                      : data.item.advisor_connection_state == 2
                        ? 'Busy'
                        : data.item.advisor_connection_state == 3
                          ? 'Away'
                          : 'Offline'}})])])])]}},{key:"cell(connection_updated_at)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.item.connection_updated_at)))])])]}},{key:"cell(eeuu_state)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(data.item.eeuu_state))])])]}},{key:"cell(tracking)",fn:function(data){return [_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"MenuIcon","size":"15"},on:{"click":function($event){return _vm.openTrackingModal(data.item.account_id)}}})]}}])})]},proxy:true}])}),(_vm.isTrackingModalOpened)?_c('tracking-modal',{attrs:{"current-client-id":_vm.currentClientId},on:{"close":_vm.closeTrackingModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }