import { amgApi } from "@/service/axios";

class ClientConnectionService {
  async getConnectionClients(params) {
    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getClientsTrackingList(params) {
    try {
      const data = await amgApi.post(
        "/clients/tracking-list-view-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
}

export default new ClientConnectionService();
