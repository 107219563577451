<template>
  <b-modal
    id="modal-entrances-tracking"
    v-model="control"
    modal-class="modal-primary"
    title="Tracking List"
    title-tag="h3"
    header-bg-variant="primary"
    size="xmd"
    hide-footer
    centered
    @hidden="close"
  >
    <b-table
      responsive
      :items="items"
      small
      :fields="fields"
      sticky-header="50vh"
    >
      <template #head(comment)="data">
        <div class="text-center">
          {{ data.label }}
        </div>
      </template>
      <template #head(advisor_name)="data">
        <div class="text-center">
          {{ data.label }}
        </div>
      </template>
      <template #head(created_at)="data">
        <div class="text-center">
          {{ data.label }}
        </div>
      </template>
      <template #cell(status_connection)="data">
        <span>{{ data.item.status_connection }}</span>
      </template>
      <template #cell(comment)="data">
        <div class="text-center">
          <span>{{ data.item.comment }}</span>
        </div>
      </template>
      <template #cell(advisor_name)="data">
        <div class="text-center">
          <span class="text-nowrap">{{ data.item.advisor_name }}</span>
        </div>
      </template>
      <template #cell(created_at)="data">
        <div class="text-center">
          <span class="text-nowrap">{{ data.item.created_at | myDateGlobalWithHour }}</span>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ClientsConnectionService from '@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service';

export default {
  props: {
    currentClientId: {
      required: true,
    },
  },
  data() {
    return {
      control: false,
      fields: [
        {
          key: 'status_connection',
          label: 'Status',
        },
        {
          key: 'comment',
          label: 'Comment',
        },
        {
          key: 'advisor_name',
          label: 'User',
        },
        {
          key: 'created_at',
          label: 'Creation Date',
        },
      ],
      items: [],
    };
  },
  async created() {
    this.control = true;
    const { data } = await ClientsConnectionService.getClientsTrackingList({ id: this.currentClientId });
    this.items = data;
  },
  methods: {
    close() {
      this.$emit('close');
      this.control = false;
    },
  },
};
</script>
<style scoped>
	.headerColor {
	background-color: #0090e7 !important
	}
</style>
