export default [
  {
    key: 'lead_name',
    label: 'Name',
    class: 'text-left',
    visible: true,
  },
  {
    key: 'program',
    label: 'Program',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'advisor_name',
    label: 'Advisor',
    class: 'text-center',
    visible: false,
  },
  {
    key: 'advisor_connection_name',
    label: 'Agent',
    visible: true,
  },
  {
    key: 'connection_updated_at',
    label: 'Recovered Date',
    visible: true,
  },
  {
    key: 'eeuu_state',
    label: 'State',
    visible: true,
  },
  {
    key: 'tracking',
    label: 'Tracking',
    class: 'text-center',
    visible: true,
  },
];
